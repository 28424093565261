import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Header from './Header';
import { Card, CardContent } from "./ui/card";
import { ImageIcon } from 'lucide-react';

function HomePage() {
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/categories`);
        setCategories(response.data);
      } catch (error) {
        console.error('Error fetching categories:', error);
      }
    };
    fetchCategories();
  }, []);

  return (
    <div className="min-h-screen bg-gray-50">
      <Header />
      <div className="container mx-auto px-4 py-8">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {categories.map(category => (
            <Link key={category._id} to={`/category/${category._id}`}>
              <Card className="group hover:shadow-lg transition-shadow duration-200">
                <CardContent className="p-0">
                  <div className="aspect-[3/2] relative bg-gray-100 overflow-hidden">
                    <div className="absolute inset-0 flex items-center justify-center text-gray-400">
                      <ImageIcon className="w-12 h-12" />
                    </div>
                  </div>
                  <div className="p-4">
                    <h2 className="text-lg font-medium text-gray-900 group-hover:text-blue-600 transition-colors">
                      {category.name}
                    </h2>
                  </div>
                </CardContent>
              </Card>
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
}

export default HomePage;