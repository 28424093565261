import React, { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import Header from './Header';
import Masonry from 'react-masonry-css';
import { Card, CardContent } from "./ui/card";

function CategoryPage() {
  const [products, setProducts] = useState([]);
  const [category, setCategory] = useState(null);
  const [loading, setLoading] = useState(true);
  const { categoryId } = useParams();

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/categories/${categoryId}/products`);
        console.log('API Response:', response.data);
        setProducts(response.data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching products:', error);
        setLoading(false);
      }
    };
    fetchProducts();
  }, [categoryId]);

  const processImageUrl = useCallback((originalUrl) => {
    if (!originalUrl) return '/placeholder.jpg';
    
    // API'ye görüntü işleme parametrelerini ekle
    const url = new URL(originalUrl.startsWith('http') 
      ? originalUrl 
      : `${process.env.REACT_APP_API_URL}${originalUrl}`
    );
    
    // Görüntü boyutunu ayarla (max 800px)
    url.searchParams.set('width', '800');
    url.searchParams.set('fit', 'inside');
    
    // Watermark ekle (düşük opasite)
    url.searchParams.set('watermark', 'true');
    url.searchParams.set('watermark-text', '© Copyright');
    url.searchParams.set('watermark-opacity', '0.15');
    url.searchParams.set('watermark-position', 'center');
    
    return url.toString();
  }, []);

  const getImageUrl = useCallback((product) => {
    if (!product.imageUrls || !Array.isArray(product.imageUrls) || product.imageUrls.length === 0) {
      console.log('No valid image URL found for product:', product._id);
      return '/placeholder.jpg';
    }

    // En son eklenen görseli al
    for (let i = product.imageUrls.length - 1; i >= 0; i--) {
      const imageUrl = product.imageUrls[i];
      if (!imageUrl) continue;

      if (typeof imageUrl === 'string') {
        return processImageUrl(imageUrl);
      } else if (typeof imageUrl === 'object' && imageUrl.url) {
        return processImageUrl(imageUrl.url);
      }
    }

    return '/placeholder.jpg';
  }, [processImageUrl]);

  const breakpointColumnsObj = {
    default: 5,
    2000: 5,
    1600: 4,
    1200: 3,
    768: 2,
    500: 1
  };

  return (
    <div className="min-h-screen bg-background">
      <Header />
      <main className="container max-w-screen-2xl py-6 lg:py-10 px-4">
        {loading ? (
          <div className="flex items-center justify-center py-10">
            <div className="text-lg">Yükleniyor...</div>
          </div>
        ) : products.length === 0 ? (
          <div className="flex items-center justify-center py-10">
            <div className="text-lg text-gray-500">Bu kategoride ürün bulunamadı.</div>
          </div>
        ) : (
          <Masonry
            breakpointCols={breakpointColumnsObj}
            className="my-masonry-grid"
            columnClassName="my-masonry-grid_column"
          >
            {products.map(product => (
              <div key={product._id}>
                <Card className="overflow-hidden group">
                  <CardContent className="p-0">
                    <div className="relative">
                      <img
                        src={getImageUrl(product)}
                        alt={product.name}
                        className="w-full h-auto object-cover"
                        loading="lazy"
                        onError={(e) => {
                          console.log('Image load error for product:', product._id);
                          e.target.onerror = null;
                          e.target.src = '/placeholder.jpg';
                        }}
                      />
                      <div className="absolute inset-0 bg-gradient-to-t from-black/60 via-transparent to-transparent opacity-0 group-hover:opacity-100 transition-opacity">
                        <div className="absolute bottom-0 left-0 right-0 p-4">
                          <div className="text-white text-sm font-medium">
                            Kod: {product.murallsWp?.code || 'N/A'}
                          </div>
                        </div>
                      </div>
                    </div>
                  </CardContent>
                </Card>
              </div>
            ))}
          </Masonry>
        )}
      </main>
    </div>
  );
}

export default CategoryPage;